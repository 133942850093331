/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import "@fontsource/libre-baskerville" // Defaults to weight 400 with all styles included.
import "@fontsource/rammetto-one"
import { useLocation } from "@reach/router"
import Cookies from "js-cookie"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { ConsentRequestButton } from "components/CookieBanner"
import usePreAppliedCoupon from "../hooks/usePreAppliedCoupon"
import { AuthenticationStateProvider } from "../providers/AuthenticationProvider"
import useExitIntent from "../components/ExitIntent/useExitIntent"
import GlobalStyle from "../components/GlobalStyles"
import useHubspotTracking from "../hooks/useHubspotTracking"
import useReferral from "../hooks/useReferral"
import Apollo from "../providers/Apollo"
import { createTheme, DEFAULT_THEME } from "../styles/Theme"
import { useConsentMode } from "../hooks/useConsentMode"

const BareLayout = ({ children }) => {
  useReferral()
  useHubspotTracking()
  const { consent, CookieBanner, showBanner } = useConsentMode()

  const location = useLocation()
  const { component } = useExitIntent()
  const { init } = usePreAppliedCoupon()

  useEffect(() => {
    init()
    const urlParams = new URLSearchParams(location.search)
    const referrer = urlParams.get("referrer")
    if (referrer) {
      Cookies.set("nzk_referrer", referrer, {
        expires: 28,
        domain: ".nightzookeeper.com",
      })
    }
    // eslint-disable-next-line
  }, [])

  let THEME = DEFAULT_THEME
  if (/teachers/.test(location.pathname)) {
    THEME = createTheme({
      primary: "#8AC55C",
      secondary: "#006736",
    })
  }

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={THEME}>
        <Apollo>
          <AuthenticationStateProvider>
            {component}
            {children}

            {CookieBanner}

            {!CookieBanner && consent?.analytics_storage === "denied" && (
              <ConsentRequestButton showBanner={showBanner} />
            )}
          </AuthenticationStateProvider>
        </Apollo>
      </ThemeProvider>
    </>
  )
}

BareLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BareLayout
