const useHubspotTracking = () => {
  let _hsq: any[] = []
  let _hsp: any[] = []
  if (typeof window !== "undefined") {
    // @ts-ignore
    _hsq = window._hsq || []
    // @ts-ignore
    _hsp = window._hsp || []
    // @ts-ignore
    window._hsq = _hsq
    // @ts-ignore
    window._hsp = _hsp
  }

  const identify = payload => {
    try {
      _hsq.push([
        "identify",
        {
          ...payload,
        },
      ])
    } catch (err) {
      console.error(err)
    }
  }

  const trackCustomEvent = (eventName, properties) => {
    _hsq.push([
      "trackCustomBehavioralEvent",
      {
        name: eventName,
        properties,
      },
    ])
  }

  const trackPage = path => {
    try {
      _hsq.push(["setPath", path])
      _hsq.push(["trackPageView"])
    } catch (err) {
      console.error(err)
    }
  }

  const trackEvent = (payload: any) => {
    _hsq.push([
      "trackEvent",
      {
        ...payload,
      },
    ])
  }

  return {
    _hsq,
    identify,
    trackPage,
    trackEvent,
    trackCustomEvent,
  }
}

export default useHubspotTracking
