import { useLocation } from '@reach/router'

const LS_KEY = 'nzk-coupon'

const usePreAppliedCoupon = () => {

  const location = useLocation()

  const storeCoupon = (coupon: string) => {
    localStorage.setItem(LS_KEY, coupon)
  }

  const getCoupon = (): string | undefined => {
    const couponId = localStorage.getItem(LS_KEY)
    if (couponId) {
      return couponId
    }
  }

  const clearCoupon = () => {
    const couponId = localStorage.getItem(LS_KEY)
    if (couponId) {
      localStorage.removeItem(LS_KEY)
    }
  }

  const init = () => {
    const urlParams = new URLSearchParams(location.search)
    const coupon = urlParams.get('coupon')
    if (coupon) {
      storeCoupon(coupon)
    }
  }

  return {
    init,
    getCoupon,
    clearCoupon
  }
}

export default usePreAppliedCoupon
