import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const StyledTrustpilotWidget = styled.div`
  width: 100%;
  padding: 30px 15px;
  max-width: 930px;
  margin: 0 auto;
`

interface IProps {
  templateId: string
  businessUnitId?: string
  theme?: string
  fontFamily?: string
  locale?: string
  height?: string
  width?: string
  href?: string
  reviewLanguage?: string // 'en'
  stars?: string // '4,5'
  quoteColor?: string
  tags?: string
}

export const TrustpilotWidgetBase = (props: IProps) => {
  const trustpilotRef = useRef<HTMLDivElement | null>(null)

  const businessUnitId = props.businessUnitId || '5e99bfb13e39470001f6ee60'
  const theme = props.theme || 'light'
  const fontFamily = props.fontFamily || 'Libre Baskerville'
  const locale = props.locale || 'en-us'
  const height = props.height || '150px'
  const width = props.width || '100%'
  const href = props.href || 'https://www.trustpilot.com/review/nightzookeeper.com'
  const stars = props.stars // || '4,5'
  const reviewLanguage = props.reviewLanguage || 'en'
  const quoteColor = props.quoteColor
  const tags = props.tags

  const onTrustpilotLoaded = () => {
    // @ts-ignore
    if (trustpilotRef.current && window.Trustpilot) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(trustpilotRef.current);
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (typeof window.Trustpilot === 'undefined') {
      const s = document.querySelector('script#trustpilot-widget') as HTMLScriptElement
      s?.addEventListener('load', () => {
        onTrustpilotLoaded()
      }, {  once: true })
    } else {
      onTrustpilotLoaded()
    }
  }, [])


  return <>
    <Helmet>
      <script id='trustpilot-widget' type='text/javascript' async src='https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'/>
    </Helmet>
    <StyledTrustpilotWidget>
      <div ref={trustpilotRef}
        data-locale={locale}
        data-template-id={props.templateId}
        data-businessunit-id={businessUnitId}
        data-style-height={height}
        data-style-width={width}
        data-theme={theme}
        data-font-family={fontFamily}
        data-review-language={reviewLanguage}
        data-stars={stars}
        data-tags={tags}
        data-quote-color={quoteColor}
      >
        { /* eslint-disable-next-line react/jsx-no-target-blank */ }
        <a href={href} target="_blank" rel="noopener">Trustpilot</a>
      </div>
    </StyledTrustpilotWidget>
  </>
}
