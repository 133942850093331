import React, { useMemo, useState } from "react"
import styled, { css } from "styled-components"

const Wrapper = styled.div<{ $disabled?: boolean; $height: string }>`
  --checkbox-height: ${props => props.$height};
  --checkbox-width: calc(var(--checkbox-height) * 2);

  position: relative;

  width: var(--checkbox-width);
  height: var(--checkbox-height);
  border-radius: 100px;

  > .circle {
    position: absolute;
    left: 2px;
    top: 2px;
    width: calc(var(--checkbox-height) - 4px);
    height: calc(var(--checkbox-height) - 4px);
    background-color: #ffffff;
    border-radius: 50%;
  }

  transition: background-color 200ms;
  &[data-toggled="false"] {
    > .circle {
      transform: translateX(0);
    }
    background-color: #8f96a3;

    ${props =>
      !props.$disabled &&
      css`
        :hover {
          > .circle {
            transform: translateX(calc(var(--checkbox-height) * 0.3));
          }
          background-color: #5e636e;
        }
      `}
  }

  &[data-toggled="true"] {
    > .circle {
      transform: translateX(
        calc(var(--checkbox-width) - var(--checkbox-height))
      );
    }
    background-color: #239aff;

    ${props =>
      !props.$disabled &&
      css`
        :hover {
          background-color: #1976f0;
        }
      `}
  }
  transition: background-color 100ms linear;
  > .circle {
    transition: transform 100ms linear;
  }
`

interface IProps {
  initialValue?: boolean
  value?: boolean
  onChange?: (v: boolean) => void
  disabled?: boolean

  styles?: {
    height?: string
  }
}

export const Toggle = (props: IProps) => {
  const [_toggled, _setToggled] = useState(Boolean(props.initialValue))

  const [toggled, setToggled] = useMemo(() => {
    if (typeof props.value === "boolean" && props.onChange) {
      return [props.value, props.onChange]
    }
    return [_toggled, _setToggled]
  }, [props.value, props.onChange, _toggled])

  return (
    <Wrapper
      $height={props.styles?.height || "24px"}
      $disabled={props.disabled}
      data-toggled={toggled}
      onClick={() => !props.disabled && setToggled(!toggled)}
    >
      <div className="circle" />
    </Wrapper>
  )
}
