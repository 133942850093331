import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import React, { useCallback } from "react"
import Select from "react-select"
import styled from "styled-components"
import useUserPreferences from "../../hooks/useUserPreferences"

const countryCodeForLocale = {
  en: "US",
  "en-gb": "GB",
  "en-us": "US",
  "en-au": "AU",
  "en-nz": "NZ",
  "en-ca": "CA",
  "zh-hk": "HK",
  "zh-hant-hk": "HK",
  "en-hk": "HK",
  "zh-tw": "TW",
  "zh-hant-tw": "TW",
  "en-tw": "TW",
  "en-ph": "PH",
  "zh-sg": "SG",
  "zh-hans-sg": "SG",
  "en-sg": "SG",
  kr: "KR",
  "en-ko": "KR",
  jp: "JP",
  "en-ja": "JP",
  zh: "CN",
  "en-zh": "CN",
  hi: "IN",
  "en-in": "IN",
  "en-hi": "IN",
  "ar-ae": "AE",
  "en-ae": "AE",
  "en-za": "ZA",
  eu: "EU",
  "en-eu": "EU",
  "ms-my": "MY",
  "en-my": "MY",
  "sv-se": "SE",
  "en-se": "SE",
  "ar-qa": "QA",
  "en-qa": "QA",
  "ur-pk": "PK",
  "en-pk": "PK",
  "id-id": "ID",
  "en-id": "ID",
  "he-il": "IL",
  "en-il": "IL",
  "pt-br": "BR",
  "en-br": "BR",
  "es-ar": "AR",
  "en-ar": "AR",
  "es-mx": "MX",
  "en-mx": "MX",
  "es-cl": "CL",
  "en-cl": "CL",
  fr: "FR",
}

const Flag = styled.div`
  height: 24px;
  width: 35px;
  background-image: url(${(props: { src: string }) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`

const SelectOption = styled.div`
  padding: 4px 0;
  margin-right: 20px;
`

interface ICustomCountryOptionProps {
  data: { target: string; value: string }
  innerProps: any
}

const CountryCustomOption = ({
  innerProps,
  data,
}: ICustomCountryOptionProps) => {
  if (typeof window === 'undefined') return null
  let country = countryCodeForLocale[data.value]
  if (!country && data.value.split("-").length > 1) {
    country = data.value.split("-")[1].toUpperCase()
  }
  const flagSrc = `https://cdn.nightzookeeper.com/edu-assets/images/flags/${country}.png`
  return (
    <SelectOption>
      {data.target ? (
        <Link to={data.target}>
          <Flag
            {...innerProps}
            src={flagSrc}
          />
        </Link>
      ) : (
        <Flag
          {...innerProps}
          src={flagSrc}
        />
      )}
    </SelectOption>
  )
}

const SelectStyles = {
  control: () => ({
    marginRight: "-20px",
  }),
  indicatorsContainer: () => ({ display: "none" }),
  menu: provided => ({
    ...provided,
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: "0px 5px",
    marginTop: "0px",
    marginLeft: "3px",
    width: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 4px 3px rgba(0,0,0,0.4)",
  }),
}

interface IProps {
  locales: string[]
  currentLocale: string
  noTarget?: boolean
  onChange?: (value: any) => any
}

const LanguageDropdown = (props: IProps) => {
  const location = useLocation()
  const { setFavouriteLang } = useUserPreferences()

  const target = useCallback(
    locale => {
      if (props.noTarget) return null
      if (props.currentLocale === "en") {
        return `/${locale}${location.pathname}`
      }
      const splits = [...location.pathname.split("/")]
      if (locale === "en") {
        splits.splice(1, 1)
      } else {
        splits[1] = locale
      }
      return splits.join("/")
    },
    [location]
  )

  const onChange = l => {
    setFavouriteLang(l.value)
    if (props.onChange) {
      props.onChange(l)
    }
  }

  return (
    <Select
      name="country"
      onChange={l => onChange(l)}
      styles={SelectStyles}
      value={{
        value: props.currentLocale.toLowerCase(),
        label: props.currentLocale.toLowerCase(),
      }}
      isSearchable={false}
      isClearable={false}
      placeholder=""
      options={[...props.locales].map(l => {
        const value = l.toLowerCase()
        return {
          value,
          label: value,
          target: target(l),
        }
      })}
      components={{
        Option: CountryCustomOption,
        SingleValue: CountryCustomOption,
      }}
    />
  )
}

LanguageDropdown.defaultProps = {
  noTarget: false,
  onChange: null,
}

export default LanguageDropdown
