import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import HeaderUI, { HeaderOptions } from "./HeaderUI"

interface ILink {
  placeholder: string
  target: string
  external: boolean
}
interface IProps {
  links?: {
    id: number
    link: ILink
    linkGroup: { label: string; links: ILink[] }
  }[]
  pagePath?: string
  shouldHighlightLink?: (target: string) => boolean
  languageDropdownOptions?: object
  locale?: string
  availableLangs?: string[]
  options?: HeaderOptions
}

const HeaderStrapi = (props: IProps) => {
  const { strapiHomepage } = useStaticQuery(graphql`
    {
      strapiHomepage {
        header {
          links {
            link {
              placeholder
              target
              external
              id
            }
          }
        }
      }
    }
  `)

  const links = [
    ...strapiHomepage.header.links.link.map(link => ({ link })),
    ...(props.links || []),
  ]

  return <HeaderUI {...props} links={links} />
}

HeaderStrapi.defaultProps = {
  links: [],
  locale: "en",
  availableLangs: [],
  languageDropdownOptions: {},
}

export default HeaderStrapi
