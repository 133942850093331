import { graphql, useStaticQuery, navigate } from "gatsby"
import { FormClose } from "grommet-icons"
import React, { useEffect, useState } from "react"
import { useTheme } from "styled-components"
import scrollToPlansSection from "components/Strapi/actions/scrollToPlansSection"
import { useLocation } from "@reach/router"
import Apollo from "../../providers/Apollo"
import Button from "../UI/Button"
import ThanksComponent from "./Thanks"
import FormComponent from "./Form"
import * as s from "./index.styles"

interface IProps {
  dismiss: () => void
}

const ExitIntentModal = (props: IProps) => {
  const theme: any = useTheme()
  const location = useLocation()

  const [submitted, setSubmitted] = useState(false)

  const { willLogoImage, bundle2Image } = useStaticQuery(graphql`
    {
      willLogoImage: file(relativePath: { eq: "will-logo2xl.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 150)
        }
      }
      bundle2Image: file(relativePath: { eq: "bundle-2xl.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 270)
        }
      }
    }
  `)

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  const handleLearnMoreClick = () => {
    const specialOfferRegex = /\/parents(\/)?.*/
    const isSpecialOffer = specialOfferRegex.test(location.pathname)

    if (isSpecialOffer) {
      scrollToPlansSection(".PlansSection")
      props.dismiss()
      setSubmitted(true)
    } else {
      navigate("/parents")
      setSubmitted(true)
    }
  }

  return (
    <>
      <s.Overlay>
        <s.Wrapper>
          <s.QuitButton>
            <Button
              round
              theme="primary"
              disabled
              size="regular"
              onClick={props.dismiss}
            >
              <FormClose color="#fff" size="12px" />
            </Button>
          </s.QuitButton>
          {!submitted ? (
            <FormComponent
              theme={theme}
              bundle2Image={bundle2Image}
              setSubmitted={setSubmitted}
            />
          ) : (
            <ThanksComponent
              willLogoImage={willLogoImage}
              handleLearnMoreClick={handleLearnMoreClick}
              canSubmit={false}
            />
          )}
        </s.Wrapper>
      </s.Overlay>
    </>
  )
}

export default (props: IProps) => (
  <Apollo>
    <ExitIntentModal {...props} />
  </Apollo>
)
