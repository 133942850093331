import useExitIntent from "components/ExitIntent/useExitIntent"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Facebook,
  Instagram,
  Linkedin,
  Pinterest,
  Tictok,
  Twitter,
} from "grommet-icons"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import Link from "./Link"
import { TrustpilotWidgetBase } from "./TrustpilotWidgets"

const Wrapper = styled.div`
  height: 200px;
  width: 100%;
  background-color: #fff;
  color: #757575;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 1rem;
  max-width: 1100px;
  margin: 0 auto;
`

const ActionLink = styled.div`
  cursor: pointer;
  user-select: none;
`

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    color: inherit;
  }
  @media (max-width: 750px) {
    flex-direction: column;
    > * {
      margin-bottom: 20px;
    }
  }
`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 5px;
  }
  > :last-child {
    margin-bottom: 0px;
  }
`

const InlineGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 96px;
  height: 70px;
  > * {
    height: 25px;
    margin-right: 8px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Company = styled.div`
  display: flex;
  margin-top: 50px;
  padding-bottom: 30px;
  align-items: flex-end;
  > :first-child {
    width: 150px;
  }
  img {
    object-fit: contain !important;
  }
  @media (max-width: 510px) {
    flex-direction: column;
    align-items: flex-start;

    > :first-child {
      margin-bottom: 10px;
      order: 2;
    }
    > :nth-child(2) {
      align-items: flex-start;
      order: 3;
    }
  }
`

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;
  align-items: flex-end;
  gap: 10px;

  @media (max-width: 510px) {
    margin-bottom: 30px;
    align-items: flex-start;
  }
`

interface IProps {
  linkGroups?: {
    links: {
      id: number
      target: string
      onClick: () => void
      placeholder: string
      external: boolean
    }[]
  }[]
  company?: {
    logo: any
    copyright: string
    registrationNumber: string
  }
  logos?: {
    img: any
    alt?: string
  }[]
}

const Footer = (props: IProps) => {
  return (
    <Wrapper>
      <TrustpilotWidgetBase
        templateId="53aa8807dec7e10d38f59f32"
        height="130px"
      />
      <Links>
        {(props.linkGroups || []).map(({ links }, i) => (
          <Group key={i}>
            {links.map(link =>
              link.target ? (
                <Link key={link.id} {...link} />
              ) : (
                <ActionLink key={link.id} onClick={link.onClick}>
                  {link.placeholder}
                </ActionLink>
              )
            )}
          </Group>
        ))}
        <InlineGroup>
          <a href="https://twitter.com/nightzookeeper">
            <Twitter color="#757575" />
          </a>
          <a href="https://www.facebook.com/nightzookeeper">
            <Facebook color="#757575" />
          </a>
          <a href="https://pinterest.com/nightzookeeper">
            <Pinterest color="#757575" />
          </a>
          <a href="https://instagram.com/nightzookeeper">
            <Instagram color="#757575" />
          </a>
          <a href="https://www.tiktok.com/@nightzookeeperofficial">
            <Tictok color="#757575" />
          </a>
          <a href="https://linkedin.com/company/night-zookeeper">
            <Linkedin color="#757575" />
          </a>
        </InlineGroup>
      </Links>
      {props.company && (
        <Company>
          {props.company.logo && (
            <GatsbyImage
              image={getGatsbyImage(props.company.logo.localFile)}
              alt="Logo"
            />
          )}
          <Info>
            <div>{props.company.copyright}</div>
            <div>{props.company.registrationNumber}</div>
          </Info>

          <Logos>
            {props.logos &&
              props.logos.map(logo => {
                if (typeof logo.img === "string") {
                  return <img height={30} src={logo.img} alt={logo.alt} />
                }
                return (
                  <GatsbyImage
                    image={getGatsbyImage(logo.img)}
                    alt={logo.alt || ""}
                  />
                )
              })}
          </Logos>
        </Company>
      )}
    </Wrapper>
  )
}

const FooterStrapi = (props: IProps) => {
  const { component, display } = useExitIntent(false)

  const { strapiHomepage, appStoreLogo, stripeLogo } = useStaticQuery(graphql`
    {
      appStoreLogo: file(relativePath: { eq: "app-store-ipad.png" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, height: 30)
        }
      }
      stripeLogo: file(relativePath: { eq: "stripe.svg" }) {
        publicURL
      }
      strapiHomepage {
        footer {
          linksGroups {
            id
            Link {
              id
              target
              placeholder
              external
            }
          }
          companyRegistration
          copyright
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 150, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  const newLinkGroups =
    props.linkGroups && props.linkGroups.length > 0
      ? props.linkGroups
      : strapiHomepage.footer.linksGroups.map(linkGroup => ({
          links: linkGroup.Link.map(l => ({ ...l })),
        }))

  newLinkGroups[0].links.push({
    id: "newsletter",
    placeholder: "Get a Free Resource",
    onClick: () => {
      display(true)
    },
  })

  const defaultProps = {
    linkGroups: newLinkGroups,
    company: props.company || {
      copyright: strapiHomepage.footer.copyright,
      registrationNumber: strapiHomepage.footer.companyRegistration,
      logo: strapiHomepage.footer.logo,
    },
    logos: [
      {
        img: appStoreLogo,
        alt: "Appstore",
      },
      {
        img: stripeLogo.publicURL,
        alt: "Stripe",
      },
    ],
  }

  return (
    <>
      {component}
      <Footer {...props} {...defaultProps} />
    </>
  )
}

const defaultProps = {
  linkGroups: [],
  company: null,
}

Footer.defaultProps = defaultProps
FooterStrapi.defaultProps = defaultProps

export default FooterStrapi
