import styled from "styled-components"

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 91vh;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  background-color: #78359b;
  color: ${props => props.theme.secondary};
  padding: 35px 0 15px;
  border-radius: 14px;
  text-align: center;

  h3 {
    font-size: 1.3rem;
    margin: 0;
    font-weight: bold;
  }

  > :last-child {
    color: #fff;
    font-size: 0.8rem;
  }
`

export const ThanksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  max-width: 300px;
`

export const FormWrapper = styled.div`
  display: flex;
  max-width: 300px;
  overflow: auto;
  padding-top: 0 !important;
  padding: 0 15px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #ede7f6;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9575cd;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #673ab7;
  }
`

export const StyledScrollingDiv = styled.div`
  height: 100%;
  overflow: auto;
  flex: 1;
  margin-bottom: 12px;

  padding: 0 15px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #ede7f6;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9575cd;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #673ab7;
  }
`

export const ScrollWrapper = styled.div`
  height: 100%;
  flex: 1;
  margin-bottom: 12px;
`

export const QuitButton = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
`

export const Form = styled.div`
  > * {
    margin-bottom: 12px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .row {
    display: flex;
    align-items: center;
    > :last-child {
      flex: 1;
    }
  }
  .label {
    margin-bottom: 6px !important;
    text-align: left;
    margin-right: 8px;
  }
`

export const StyledHeading = styled.h3`
  font-family: "Rammetto One", cursive;
`
export const ThanksText = styled.p`
  font-size: 16px;
`
export const UserTypeSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .title {
    margin-right: 40px;
    font-family: "Rammetto One";
  }

  .radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const IconButtonWrapper = styled.div`
  width: 100%;
  > :first-child {
    width: 100% !important;

    > :nth-child(2) {
      flex: 1 !important;
      text-align: left !important;
      justify-content: flex-start !important;
      width: 100% !important;
    }
  }
`

export const Permission = styled.p`
  font-size: 12px;
`

export const Keypoints = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Keypoint = styled.div`
  display: flex;
  align-items: center;
  width: 180px;

  > .label {
    text-align: center;
    font: normal normal normal 14px/18px Rammetto One;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-left: 8px;
  }
`
