import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { Toggle } from "components/UI/Toggle"
import { IConsent } from "../../hooks/useConsentMode"

const CookieTypes = {
  necessary: "necessary",
  analytics: "analytics",
  marketing: "marketing",
} as const

type CookieType = typeof CookieTypes[keyof typeof CookieTypes]

const COOKIES = [
  {
    title: "Strictly necessary cookies",
    key: CookieTypes.necessary,
    description:
      "Strictly necessary cookies are exclusively used by us and are therefore “first party cookies”. These Cookies guarantee functions without which you cannot use our web pages as intended. For this reason, strictly necessary cookies cannot be deactivated.",
    disabled: true,
  },
  {
    title: "Analytics cookies",
    key: CookieTypes.analytics,
    description:
      "Analytics cookies Google Analytics that help us to measure the number of visitors on this website.",
  },
  {
    title: "Marketing cookies",
    key: CookieTypes.marketing,
    description:
      "Marketing cookies originate from our external advertising partners (Google, Meta, Microsoft, ByteDance, Pinterest) and are used to gather information about the websites visited by you, in order to create targeted advertising for you, for instance.",
  },
]

const Container = styled.div`
  .button {
    --button-color: #450f6b;
    --button-focus-color: #592288;
    border: 2px solid var(--button-color);
    font-family: Arial, sans-serif;
    border-radius: 8px;
    padding: 0 12px;
    line-height: 3em;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    color: var(--button-color);
    :hover {
      color: var(--button-focus-color);
      border: 2px solid var(--button-focus-color);
    }
    transition: color 200ms linear, background-color 200ms linear,
      border 200ms linear;

    &.primary {
      background-color: var(--button-color);
      font-weight: bold;
      color: #fff;
      :hover {
        background-color: var(--button-focus-color);
      }
    }
  }
`

const BottomCookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  > .cookie-disclaimer {
    background: #fff;
    border-radius: 12px 12px 0 0;

    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;

    > .actions {
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      flex-wrap: wrap;
    }
  }

  h2 {
    font-size: 24px;
    text-align: center;
    font-family: "Rammetto One", sans-serif;
    line-height: 1.3333333333;
    margin-bottom: 8px;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    text-align: center;

    > a {
      text-decoration: underline;
    }

    font-size: 11px;
    line-height: 1.6em;

    color: #5e636e;
  }

  .actions > .button {
    font-size: 12px !important;
  }

  @media screen and (max-width: 960px) {
    > .cookie-disclaimer {
      flex-direction: column;
      padding: 5px 15px 15px 15px;
      gap: 0px;
    }
  }

  @media screen and (min-width: 960px) {
    > .cookie-disclaimer {
      max-width: 900px;
      margin: 0 auto;
    }
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
`

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px 0;
  position: relative;
  max-height: 90vh;
  overflow: auto;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);

  display: flex;
  flex-direction: column;

  .cookie-image {
    position: relative;
    height: 80px;
    min-height: 80px;
    width: 100%;
    margin: 0 auto;
    background: #fff url(/images/penguin-cookie.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &:after {
      position: absolute;
      content: "";
      height: 3px;
      width: 100%;
      left: 0;
      bottom: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(69, 9, 121, 1) 30%,
        rgba(69, 9, 121, 1) 70%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  &:hover {
    .cookie-image {
      background: #fff url(/images/penguin-cookie-2.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .content {
    padding: 10px 30px 30px;
    height: 100%;
    overflow: auto;
    box-shadow: inset 0 5px 4px rgba(0, 0, 0, 0.05);
  }

  h2 {
    font-size: 24px;
    text-align: center;
    font-family: "Rammetto One", sans-serif;
    line-height: 1.3333333333;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    color: #5e636e;
    line-height: 24px;
    max-width: 700px;
    margin-bottom: 28px;
  }

  .actions {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .cookie--preferences {
    height: 100%;
    overflow: auto;
    margin: 0 -30px;
    padding: 0 30px;

    padding-bottom: 100px;
    .actions {
      position: absolute;
      box-shadow: 0 -5px 4px rgba(0, 0, 0, 0.05);

      bottom: 0px;
      width: 100%;
      padding: 10px 30px;

      left: 0;
      background-color: #fff;
    }
  }

  .cookies {
    margin-top: 20px;
    .cookie {
      display: flex;
      gap: 10px;
      > .cookie--toggle {
      }

      > .cookie--content {
        h2 {
          font-size: 18px;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 8px;
        }
        p {
          font-size: 13px;
          line-height: 25px;
        }
      }
    }
  }
`

const ConsentBannerButton = styled.div`
  position: fixed;
  user-select: none;
  cursor: pointer;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  border: 2px solid #701ea8;
  border-bottom: 0px;
  border-right: 0px;
  padding: 10px 10px 10px 20px;
  border-radius: 20px 0 0 20px;
  z-index: 1000;
  font-size: 12px;
  color: #701ea8;
  @media screen and (max-width: 450px) {
    max-width: 130px;
    text-align: center;
  }
`

interface IProps {
  dismiss: () => void
  optIn?: boolean

  consent: IConsent | null
  setConsent: (v: IConsent) => void
}

export const CookieBanner = (props: IProps) => {
  const [view, setView] = useState<"MAIN" | "PREFERENCES">("MAIN")

  const [consent, setConsent] = useState<{
    [key in CookieType]: boolean
  }>({
    necessary: true,
    marketing: props.consent?.ad_storage === "granted" || Boolean(props.optIn),
    analytics:
      props.consent?.analytics_storage === "granted" || Boolean(props.optIn),
  })

  const onConfirmChoices = () => {
    const c = (v: boolean) => (v ? "granted" : "denied")
    props.setConsent({
      ad_storage: c(consent.marketing),
      analytics_storage: c(consent.analytics),
      functionality_storage: c(consent.necessary),
      personalization_storage: c(consent.necessary),
      security_storage: c(consent.necessary),
      ad_user_data: c(consent.marketing),
      ad_personalization: c(consent.marketing),
    })

    props.dismiss()
  }

  const onAcceptAll = () => {
    props.setConsent({
      ad_storage: "granted",
      analytics_storage: "granted",
      functionality_storage: "granted",
      personalization_storage: "granted",
      security_storage: "granted",
      ad_user_data: "granted",
      ad_personalization: "granted",
    })
    props.dismiss()
  }

  // useEffect(() => {
  //   document.body.style.overflow = "hidden"
  //   return () => {
  //     document.body.style.overflow = "auto"
  //   }
  // }, [])

  return (
    <Container>
      {view === "MAIN" && (
        <>
          <Overlay>
            <BottomCookieBanner>
              <div className="cookie-disclaimer">
                <div className="cookie-disclaimer--content">
                  <p>
                    We serve cookies on this site to analyze traffic, remember
                    your preferences, and optimize your experience.
                  </p>{" "}
                  <p>
                    To find out more about the cookies we use, see our{" "}
                    <a href="/privacy-policy">Privacy Policy</a>
                  </p>
                </div>
                <div className="actions">
                  <div
                    className="button"
                    role="button"
                    tabIndex={0}
                    onClick={() => setView("PREFERENCES")}
                    onKeyPress={e =>
                      e.key === "Enter" && setView("PREFERENCES")
                    }
                  >
                    Preferences
                  </div>

                  <div
                    className="button primary"
                    role="button"
                    tabIndex={0}
                    onClick={() => onAcceptAll()}
                    onKeyPress={e => e.key === "Enter" && onAcceptAll()}
                  >
                    Accept all
                  </div>
                </div>
              </div>
            </BottomCookieBanner>
          </Overlay>
        </>
      )}
      {view === "PREFERENCES" && (
        <>
          <Overlay>
            <Wrapper>
              <div className="cookie-image" />
              <div className="content">
                <div className="cookie--preferences">
                  <h1>Preferences</h1>
                  <div className="cookies">
                    {COOKIES.map(cookie => {
                      return (
                        <div key={cookie.key} className="cookie">
                          <div className="cookie--toggle">
                            <Toggle
                              disabled={cookie.disabled}
                              value={consent[cookie.key]}
                              onChange={() => {
                                setConsent(consent => {
                                  const v = consent[cookie.key]
                                  return {
                                    ...consent,
                                    [cookie.key]: !v,
                                  }
                                })
                              }}
                            />
                          </div>
                          <div className="cookie--content">
                            <h2>{cookie.title}</h2>
                            <p>{cookie.description}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div
                    className="actions"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div
                      className="button"
                      onKeyPress={e => e.key === "Enter" && setView("MAIN")}
                      role="button"
                      tabIndex={0}
                      onClick={() => setView("MAIN")}
                    >
                      Back
                    </div>
                    <div
                      className="button primary"
                      role="button"
                      tabIndex={0}
                      onKeyPress={e => e.key === "Enter" && onConfirmChoices()}
                      onClick={() => onConfirmChoices()}
                    >
                      Confirm choices
                    </div>
                  </div>
                </div>
              </div>
            </Wrapper>
          </Overlay>
        </>
      )}
    </Container>
  )
}

export const ConsentRequestButton = (props: { showBanner: () => void }) => {
  return (
    <>
      <ConsentBannerButton
        onClick={() => {
          props.showBanner()
        }}
      >
        Manage preferences
      </ConsentBannerButton>
    </>
  )
}

export const useCookieBanner = (props: {
  consent: IConsent | null
  setConsent: (v: IConsent) => void
}) => {
  const [visible, setVisible] = useState(false)

  const dismiss = () => {
    setVisible(false)
  }

  const Modal = useMemo(() => {
    if (!visible) return null
    return (
      <CookieBanner
        dismiss={dismiss}
        consent={props.consent}
        setConsent={props.setConsent}
      />
    )
  }, [visible])

  const open = () => {
    setVisible(true)
  }

  return {
    Modal,
    open,
    dismiss,
  }
}
