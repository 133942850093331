import React, { forwardRef } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 13px 0 8.5px;

  .trustpilot {
    width: 100%;
    display: flex;
    max-width: 250px;
    margin: 0 auto;
    justify-content: center;
    gap: 8px;
  }

  .trustpilot--logo {
    max-width: 67px;
    width: 100%;
    height: 100%;
    display: block;
  }

  .trustpilot--stars {
    max-width: 80.15px;
    width: 100%;
    height: 14.73px;
    display: block;
  }

  .trustpilot--rating {
    color: #fff;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 155.09%;
    margin: 0;
  }
`

export const TrustpilotBanner = forwardRef<
  HTMLDivElement,
  { className?: string }
>((props: { className?: string }, ref) => {
  return (
    <Wrapper
      ref={ref}
      className={`trustpilot-banner ${props.className ? props.className : ""}`}
    >
      <div className="trustpilot">
        <img
          className="trustpilot--logo"
          alt="Trustpilot logo"
          src="/images/tp-logo.png"
        />
        <img
          className="trustpilot--stars"
          alt="Star rating"
          src="/images/tp-stars.png"
        />
        <div className="trustpilot--rating">4.7 • Excellent</div>
      </div>
    </Wrapper>
  )
})

TrustpilotBanner.displayName = "TrustpilotBanner"
