import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Checkmark } from "nzk-react-components"
import getGatsbyImage from "utils/getGatsbyImage"
import Button from "../UI/Button"
import * as s from "./index.styles"

interface IProps {
  willLogoImage: any
  handleLearnMoreClick: () => void
  canSubmit: boolean
}

const commonButtonProps = {
  theme: "confirm" as const,
  round: true,
  size: "small" as const,
}

const ThanksComponent = (props: IProps) => {
  const { willLogoImage, handleLearnMoreClick } = props

  const checkmarkButtons = [
    { label: "Spelling" },
    { label: "Grammar" },
    { label: "Vocabulary" },
    { label: "Reading" },
    { label: "Writing" },
  ]

  return (
    <s.ThanksWrapper>
      <div>
        <s.StyledHeading>Thank You!</s.StyledHeading>
      </div>
      <s.StyledScrollingDiv>
        <s.ThanksText>
          Check your email inbox now for your resource.
        </s.ThanksText>
        <GatsbyImage
          image={getGatsbyImage(willLogoImage)}
          alt="Free Resources"
        />
        <s.ThanksText>Join our fantastically fun program!</s.ThanksText>
        <s.ThanksText>Improve your child's:</s.ThanksText>
        <s.Keypoints>
          {checkmarkButtons.map((buttonProps, index) => (
            <s.Keypoint key={index}>
              <Button {...commonButtonProps} {...buttonProps} size="x-small">
                <Checkmark />
              </Button>
              <div className="label">{buttonProps.label}</div>
            </s.Keypoint>
          ))}
        </s.Keypoints>
      </s.StyledScrollingDiv>
      <div>
        <Button onClick={handleLearnMoreClick} theme="primary" size="small">
          Learn More
        </Button>
      </div>
    </s.ThanksWrapper>
  )
}

export default ThanksComponent
