import React, { useMemo, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useApolloClient } from "@apollo/client/react"
import gql from "graphql-tag"
import { useLocation } from "@reach/router"
import getGatsbyImage from "utils/getGatsbyImage"
import { Checkmark, IconButton } from "nzk-react-components"
import useHubspotTracking from "../../hooks/useHubspotTracking"
import Button from "../UI/Button"
import Input from "../UI/Input"
import * as s from "./index.styles"

interface IProps {
  theme: any
  bundle2Image: any
  setSubmitted: (p: boolean) => void
}

const FormComponent = (props: IProps) => {
  const { theme, bundle2Image, setSubmitted } = props

  const [userType, setUserType] = useState<
    "Teacher" | "Parent" | "Homeschooler"
  >("Parent")
  const location = useLocation()
  const { identify, trackEvent } = useHubspotTracking()
  const [formData, setFormData] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
  })
  const client = useApolloClient()

  const canSubmit = useMemo(() => {
    const emailRe =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return (
      formData.firstName !== "" &&
      formData.lastName !== "" &&
      emailRe.test(formData.email)
    )
  }, [formData])

  const submit = async () => {
    if (!canSubmit) return
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!re.test(String(formData.email).toLowerCase())) return
    await client.mutate({
      mutation: gql`
        mutation ($name: String, $email: String!) {
          submitExitIntentPopup(name: $name, email: $email)
        }
      `,
      variables: {
        email: formData.email,
        name: `${formData.firstName} ${formData.lastName}`,
      },
    })
    let contact_type = "Parent"
    let homeschooler = "false"

    if (userType === "Homeschooler") {
      homeschooler = "true"
    } else if (userType === "Teacher") {
      contact_type = "Teacher"
    }

    // analytics && analytics.logEvent('submit_exit_intent', { email })
    identify({
      email: formData.email,
      firstname: formData.firstName,
      lastname: formData.lastName,
      contact_type,
      homeschooler,
    })
    trackEvent({ id: "Exit Intent Form Submitted" })
    setSubmitted(true)
    try {
      if (typeof window !== "undefined") {
        window.postMessage(
          {
            event: "ExitIntentSubmitted",
            payload: {
              email: formData.email,
              firstName: formData.firstName,
              lastName: formData.lastName,
            },
          },
          location.origin
        )
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <s.StyledHeading>Get a free resource!</s.StyledHeading>
      <s.FormWrapper>
        <s.ScrollWrapper>
          <GatsbyImage
            image={getGatsbyImage(bundle2Image)}
            style={{ margin: "10px 0", width: "260px" }}
            alt="Free Resources"
          />
          <s.Form>
            <Input
              borderColor={theme.secondary}
              placeholder="First name"
              value={formData.firstName}
              onChange={e =>
                setFormData(f => ({ ...f, firstName: e.target.value }))
              }
            />
            <Input
              borderColor={theme.secondary}
              placeholder="Last name"
              value={formData.lastName}
              onChange={e =>
                setFormData(f => ({ ...f, lastName: e.target.value }))
              }
            />
            <Input
              borderColor={theme.secondary}
              placeholder="Email"
              value={formData.email}
              onChange={e =>
                setFormData(f => ({ ...f, email: e.target.value }))
              }
            />
          </s.Form>
          <s.UserTypeSelector>
            <div className="title">
              <p>I am a:</p>
            </div>
            {["Homeschooler", "Parent", "Teacher"].map(option => (
              <s.IconButtonWrapper key={option}>
                <IconButton
                  theme={userType === option ? "confirm" : "purple"}
                  icon={userType === option ? <Checkmark /> : undefined}
                  size="small"
                  onClick={() => {
                    setUserType(
                      option as "Homeschooler" | "Parent" | "Teacher"
                    )
                    setFormData(f => ({ ...f, userType: option }))
                  }}
                >
                  {option}
                </IconButton>
              </s.IconButtonWrapper>
            ))}
          </s.UserTypeSelector>
          <s.Permission>
            By entering your email, you give Night Zookeeper permission to
            contact you about their product and services.
          </s.Permission>
        </s.ScrollWrapper>
      </s.FormWrapper>
      <Button
        theme="primary"
        size="small"
        disabled={!canSubmit}
        onClick={submit}
      >
        Submit
      </Button>
    </>
  )
}

export default FormComponent
