import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled, { css } from "styled-components"

interface IProps {
  target: string
  placeholder?: string
  external?: boolean
  children?: any
  selected?: boolean
}

const LinkEl = styled.span<{ $selected: boolean }>`
  ${props =>
    props.$selected &&
    css`
      position: relative;
      font-weight: bold;

      &:before {
        position: absolute;
        content: "";
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
      }
    `}
`

const Link = (props: IProps) => {
  const EXTERNAL = props.external || /^http/.test(props.target)
  if (EXTERNAL)
    return (
      <a href={props.target}>
        <LinkEl $selected={Boolean(props.selected)}>
          {props.children || props.placeholder}
        </LinkEl>
      </a>
    )
  return (
    <GatsbyLink to={props.target}>
      <LinkEl $selected={Boolean(props.selected)}>
        {props.children || props.placeholder}
      </LinkEl>
    </GatsbyLink>
  )
}

export default Link
