import { useLocation } from "@reach/router"
import { useCookieBanner } from "components/CookieBanner"
import Cookies from "js-cookie"
import { useEffect, useMemo, useState } from "react"
import config from "../../config"

// https://blog.cloud66.com/google-tag-manager-consent-mode-v2-in-react

const COOKIE_CONFIGS = {
  production: {
    domain: "nightzookeeper.com",
    secure: true,
    sameSite: "Lax",
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  },
  staging: {
    domain: "preview-nzk.gtsb.io",
    secure: true,
    sameSite: "Lax",
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  },
  development: {
    domain: "localhost",
    secure: false,
    sameSite: "Lax",
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  },
} as const

const getConfig = () => {
  if (config.env === "development") {
    return COOKIE_CONFIGS.development
  }
  return COOKIE_CONFIGS.production
}

const COOKIE_CONFIG = {
  name: "NZK_CONSENT",
  options: getConfig(),
}

type ConsentOption = "granted" | "denied"

export interface IConsent {
  ad_storage: ConsentOption
  analytics_storage: ConsentOption
  functionality_storage: ConsentOption
  personalization_storage: ConsentOption
  security_storage: ConsentOption
  ad_user_data: ConsentOption
  ad_personalization: ConsentOption
}

export const useConsentMode = () => {
  const { pathname } = useLocation()
  const getConsent = () => {
    const rawConsent = Cookies.get(COOKIE_CONFIG.name)
    if (rawConsent) {
      return JSON.parse(rawConsent) as IConsent
    }
    return null
  }

  const [consent, _setConsent] = useState<IConsent | null>(getConsent())

  useEffect(() => {
    _setConsent(getConsent())
  }, [])

  const dataLayer =
    typeof window !== "undefined"
      ? // @ts-ignore
        window.dataLayer || []
      : []

  function gtag() {
    // eslint-disable-next-line
    dataLayer.push(arguments)
  }

  const sendConsent = (consent: IConsent) => {
    // @ts-ignore
    gtag("consent", "update", consent)
    dataLayer.push({
      event: "ConsentUpdated",
    })
  }

  const updateCookie = (consent: IConsent) => {
    Cookies.set(
      COOKIE_CONFIG.name,
      JSON.stringify(consent),
      COOKIE_CONFIG.options
    )
    if (typeof window !== "undefined") {
      sessionStorage.setItem("last-shown-cookies", `${Date.now()}`)
    }

    // Cookies.set("NZK-CONSENT-SHOWN", `${Date.now()}`, {
    //   expires: Date.now() + 3600,
    //   secure: false,
    //   sameSite: "Lax",
    //   path: "/",
    // })
  }

  const setConsent = (consent: IConsent | null) => {
    const existing = getConsent()
    const HAS_CHANGED =
      !existing || JSON.stringify(consent) !== JSON.stringify(existing)

    if (HAS_CHANGED && consent) {
      updateCookie(consent)
      sendConsent(consent)
      _setConsent(consent)
    }
  }

  // const { open } = useConsentBanner({
  //   onChange: consent => setConsent(consent),
  // })

  const { open, Modal: CookieBanner } = useCookieBanner({
    consent,
    setConsent,
  })

  const shouldShow = useMemo(() => {
    if (pathname.match(/\/privacy-policy/)) return false
    if (typeof window !== "undefined") {
      const lastShown = sessionStorage.getItem("last-shown-cookies") || "0"
      if (parseInt(lastShown, 10) + 30 * 60 * 1000 > Date.now()) return false
    }
    return true
  }, [pathname])

  useEffect(() => {
    // const consentDone = Cookies.get("NZK-CONSENT-DONE")
    // if (!consent && !consentDone) {
    if (!consent && shouldShow) {
      open()
    }
  }, [consent])

  return {
    consent,
    setConsent,
    showBanner: open,
    CookieBanner,
  }
}
